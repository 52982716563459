<template>
  <span>
    <span :id="'egress-list-' + port.gpk" class="highlight-link">
              {{ vlans.length }}x Egress
    </span>
    <b-popover
      :target="'egress-list-' + port.gpk"
      triggers="hover" custom-class="popover-wide"
      placement="bottom">
      <b-table :fields="vlan_fields"
               :items="vlans"
               sticky-header>
        <template v-slot:cell(is_tagged)="data" >
          <CheckMark :state="check_tagged(data.item)" />
        </template>
        <template v-slot:cell(name)="data" >
          <RouterLink :to="'/netdoc/bcds/' + data.item.bcd">
            {{ data.item.name }}
          </RouterLink>
        </template>
        <template v-slot:cell(id)="data" >
          <RouterLink :to="'/netdoc/bcds/' + data.item.bcd">
            {{ data.item.id }}
          </RouterLink>
        </template>
      </b-table>
    </b-popover>
  </span>
</template>
<script>

import CheckMark from '@/components/CheckMark.vue'

export default {
  name: 'EgressList',
  components: { CheckMark },
  data() {
    return {
      vlan_fields: [
        {
          label: this.$t('system.vlan_id'),
          key: 'id'
        },
        {
          label: this.$t('system.vlan_name'),
          key: 'name'
        },
        {
          label: this.$t('views.nd.bcd.tagged'),
          key: 'is_tagged'
        },
        {
          label: this.$t('system.netinst_nc'),
          key: 'net_instnc'
        },
      ],
    }
  },
  props: {
    vlans: {
      type: Array,
      required: true,
      default() {
        return []
      }
    },
    egress: {
      type: Object,
      required: true
    },
    port: {
      type: Object,
      required: true
    }
  },
  methods: {
    check_tagged(vlan) {
      if (this.egress[this.port.gpk]) {
        if (this.egress[this.port.gpk][vlan.gpk]) {
          return this.egress[this.port.gpk][vlan.gpk].is_tagged
        }
      }
      return false
    }
  }
}
</script>

<style>
.highlight-link {
  text-decoration-style: dashed;
  text-decoration-line: underline;
  cursor: help;
}
</style>
