<template>
  <div>
    <template v-if="state">
      <NETVSIcon style="color: var(--success)" icon="check"/>
      <slot name="true_text"></slot>
    </template>
    <template v-else>
      <NETVSIcon style="color: var(--danger)" icon="close"/>
      <slot name="false_text"></slot>
    </template>
  </div>
</template>

<script>

import NETVSIcon from '@/icons/NETVSIcon.vue'

export default {
  name: 'CheckMark',
  components: {NETVSIcon},
  props: {
    state: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
